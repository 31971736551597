import APIService from './APIService';

export default {
  index(dadosGeograficos = 0) {
    return APIService.apiCall().get(
      `/relatorios/index?dados_geograficos=${dadosGeograficos}`
    );
  },
  getRelatoriosFavoritados(dadosGeograficos = 0) {
    return APIService.apiCall().get(
      `/relatorios/get-relatorios-favoritados?dados_geograficos=${dadosGeograficos}`
    );
  },
  getRelatorio(id) {
    return APIService.apiCall().get(`/relatorios/get-relatorio/${id}`);
  },
  save(postData) {
    return APIService.apiCall().post(
      '/relatorios/save',
      JSON.stringify(postData)
    );
  },
  saveSimbologiaConfig(id, postData) {
    return APIService.apiCall().post(
      `/relatorios/save-simbologia-config/${id}`,
      JSON.stringify(postData)
    );
  },
  salvarCustomizado(postData) {
    return APIService.apiCall().post(
      '/relatorios/customizados/salvar',
      JSON.stringify(postData)
    );
  },
  getDadosUnicosPorCampo(id, indiceCampoCsv, maximoDados = 10) {
    return APIService.apiCall().get(
      `/relatorios/get-dados-unicos-campo/${id}/${indiceCampoCsv}?maximo_dados=${maximoDados}`
    );
  },
  getRangeValoresPorCampo(id, indiceCampoCsv) {
    return APIService.apiCall().get(
      `/relatorios/get-range-valores-campo/${id}/${indiceCampoCsv}`
    );
  },
  deletar(id) {
    return APIService.apiCall().get(`/relatorios/excluir/${id}`);
  },
  duplicar(id) {
    return APIService.apiCall().get(`/relatorios/duplicar/${id}`);
  },
  executar(id) {
    return APIService.apiCall().get(`/relatorios/executar/${id}`);
  },
  favoritar(id) {
    return APIService.apiCall().get(`/relatorios/favoritar/${id}`);
  },
  getRelatorioSpreadSheet(id, tipo, config = {}) {
    return APIService.apiCall().get(`/relatorios/download/${id}/${tipo}`, {
      responseType: 'blob',
      ...config
    });
  },
  preVisualizar(id, retornaDadosGeograficos = 0) {
    return APIService.apiCall().get(
      `/relatorios/pre-visualizar/${id}?retorna_dados_geograficos=${retornaDadosGeograficos}`
    );
  },
  getRelatoriosFilhosByModeloId(id) {
    return APIService.apiCall().get(
      `/relatorios/get-relatorios-filhos-by-modelo-id/${id}`
    );
  },
  saveRelatorioFromModelo(postData) {
    return APIService.apiCall().post(
      '/relatorios/save-relatorio-from-modelo',
      JSON.stringify(postData)
    );
  },
  getRelatoriosByStatus(status, dadosGeograficos = 0) {
    return APIService.apiCall().get(
      `/relatorios/get-relatorios-by-status/${status}/${dadosGeograficos}`
    );
  }
};
