<template>
  <base-material-card
    color="primary"
    icon="mdi-eye-outline"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">
        Visualização do relatório: {{ relatorioNome }}
      </div>
    </template>
    <v-row>
      <v-col
        cols="12"
        class="mt-1"
      >
        <v-alert
          dense
          outlined
          type="info"
          color="blue"
          class="mb-0 py-0 px-1"
        >
          Essa tabela exibe no máximo <b>500</b> registros. Caso queira
          visualizar o relatório completo,
          <a
            href="javascript:void(0)"
            @click="downloadSpreadSheet(relatorioId, relatorioNome)"
            >clique aqui</a
          >
          para fazer o download da planilha.
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        class="mt-1"
      >
        <v-data-table
          multi-sort
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
          :options="{ multiSort: false }"
          loading-text="Carregando tabela"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
        >
          <template v-slot:item="row">
            <tr>
              <td
                v-for="(element, i) in row.item"
                :key="i"
              >
                {{ element | treatDinamic(headers[i].type) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import RelatoriosService from '@/services/RelatoriosService';
import downloadSpreadSheetMixins from '@/mixins/downloadSpreadSheetMixins';

export default {
  props: {
    relatorioId: {
      type: String,
      required: false
    }
  },

  mixins: [downloadSpreadSheetMixins],

  data: () => ({
    loading: false,
    headers: [],
    items: [],
    relatorioNome: ''
  }),

  created() {
    if (this.relatorioId) {
      this.headers, (this.items = []);
      this.getNomeRelatorio();
      this.getDados();
    }
  },

  methods: {
    getNomeRelatorio() {
      RelatoriosService.getRelatorio(this.relatorioId)
        .then((response) => {
          this.relatorioNome = response.data.data.nome;
        })
        .catch(() =>
          this.$toast.error('Erro ao buscar nome do relatório.', '', {
            position: 'topRight'
          })
        );
    },
    getDados() {
      this.loading = true;
      RelatoriosService.preVisualizar(this.relatorioId)
        .then((response) => {
          for (let i = 0; i < response.data.cabecalho.length; i++) {
            let nomeColuna = response.data.cabecalho[i];
            this.headers.push({
              value: String(i),
              text: nomeColuna,
              divider: false,
              type: JSON.parse(response.data.arrCamposTiposDados)[nomeColuna]
            });
          }

          response.data.dados.forEach((linha) => {
            this.items.push(linha);
          });
        })
        .catch(() =>
          this.$toast.error('Erro na visualização do relatório.', '', {
            position: 'topRight'
          })
        )
        .finally(() => (this.loading = false));
    }
  },

  watch: {
    relatorioId(newVal) {
      if (newVal) {
        this.loading = false;
        this.headers, (this.items = []);
        this.getNomeRelatorio();
        this.getDados();
      }
    }
  }
};
</script>

<style>
.v-data-table table thead tr th {
  font-weight: 300;
  font-size: 14.5px;
  margin: 0px 0px;
  padding-left: 8px;
  padding-right: 24px;
}

.v-data-table table tbody tr td {
  font-size: 13px;
}
</style>
